import Title from './components/Title';
import axios from 'axios'
import background from './gato.png'
import React, {useState, useRef} from 'react';

const App: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [showNSFW, setShowNSFW] = useState(false);

  const toggleNSFW = (event: any) => {
    setShowNSFW(!showNSFW);
  }

  let [captchaToken, setCaptchaToken] = useState(null);
  let captchaRef = useRef<any>(null);

  interface Window {
    grecaptcha: any;
    test: () => void;
  }

  const verify = () => {
    captchaRef.current.getResponse().then((res: any) => {
      setCaptchaToken(res)
    })
  }




  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_APP_SITE_KEY, {action: 'submit'}).then(async function (token: any) {
        console.log("sending with token", token);
        // Add your logic to submit to your backend server here.
        await axios.post(process.env.REACT_APP_API_URL + '/addemail', {token, email})
          .then((res: any) => {
            setSubmitted(true);
            console.log(res);
          })
          .catch((error: any) => {
            console.log(error);
          }

          );
      });
    });
  };

  return (
    <div style={{backgroundImage: `url(${background})`, backgroundSize: "1700px", backgroundRepeat: "no-repeat", backgroundPosition: "center top -230px"}}>
      <div className="container">
        <header className="box title">
          <Title />
          <a target="_blank" href="https://www.patreon.com/real_tsukinoneko" rel="noreferrer">Patreon</a>
        </header>
        <main className="box content">
          <div className="section">
            <p>I write stories about queerness, neurodivergence trauma, and ideas of community and belonging.
              Most of my writing contains themes and/or subjects that may make the reader
              uncomfortable. They often explore unconventional relationship structures, power exchange, 
              and surviving difficult histories. Those themes make most of my writing varying degrees of NSFW. 
              Please proceed responsibly.</p>
            {/* <label> */}
            {/*   <input id="nsfwCheckbox" type="checkbox" checked={showNSFW} onChange={toggleNSFW} /> */}
            {/*   I'd also like to see more smut-centric (NSFW) stories. */}
            {/* </label> */}

          </div>
          <div className="stories section">
            <div className="storyContainer">
              <div className="storyTitle"><a target="_blank" href="https://archiveofourown.org/works/46808866/chapters/117901933" rel="noreferrer">Pull Me Out of This</a></div>
              <div className="description">My biggest project: a kinky soulmates story. Themes of trauma, personal growth, and overcoming internalized expectations. Very intense, contains a lot of sexual and non sexual kink.</div>
            </div>
            <div className="storyContainer">
              <div className="storyTitle"><a target="_blank" href="https://archiveofourown.org/works/52870813/chapters/133733620" rel="noreferrer">Coffee Date</a></div>
              <div className="description">Two experienced practitioners who, for each their own reason, are dating again. Set in a universe where power exchange can power magic rituals.</div>
            </div>
            <div className="storyContainer">
              <div className="storyTitle"><a target="_blank" href="https://www.amazon.com/dp/B0CQKMXKKS" rel="noreferrer">Catgirl: A Novella</a></div>
              <div className="description">A 35k word novella about identity, neurodivergence, and acceptance. Extremely SFW and cozy.</div>
            </div>
            <div className="storyContainer">
              <div className="storyTitle"><a target="_blank" href="https://readonlymind.com/@TsukiNoNeko/RitualoftheFamiliar/" rel="noreferrer">Ritual of the Familiar</a></div>
              <div className="description">Students at the Sinslar Graduate Academy of Magic need to win a single duel to be eligible for graduation, but they stand to lose far more than an education. A (dark) short story.</div>
            </div>
            <div className="storyContainer">
              <div className="storyTitle"><a target="_blank" href="https://archiveofourown.org/works/54046561/chapters/136826092" rel="noreferrer">Captured Catgirl</a></div>
              <div className="description">In a world where catpeople are second class citizens, one catgirl finds a few humans she can trust. Can they find a way to make her trust them back? Extremely dark.</div>
            </div>
            {/* {showNSFW ? ( */}
            {/*   <div className="storyContainer"> */}
            {/*     <div className="storyTitle"><a target="_blank" href="https://readonlymind.com/@TsukiNoNeko/EssenceoftheFamiliar/" rel="noreferrer">Essence of the Familiar</a></div> */}
            {/*     <div className="description">Sequel to Ritual of the Familiar. Explores the difficulties of developing power exchange relationship. Much smuttier.</div> */}
            {/*   </div> */}
            {/* ) : null} */}


          </div>
          <div className="section">
            <p>For the very occasional email about new projects <i>(not chapter updates)</i>, fill out this form!</p>
            {submitted ? (
              <p>Thank you for subscribing!</p>
            ) : (
              <>
                <form className="emailForm" onSubmit={handleSubmit}>
                  <input

                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit">Subscribe</button>
                </form>
                <div className="g-recaptcha"
                  data-sitekey="6Ldrb4slAAAAAAxTur3CUpW8EnPR6TEXDPBN-dER"
                  data-callback="onSubmit"
                  data-size="invisible">
                </div>

                {error && <p>{error}</p>}
              </>
            )}

          </div>
          <div className="section footer">
            Contact: <a href="mailto:official@tsukinoneko.ink">official@tsukinoneko.ink</a>

            <div className="recaptcha">

              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
            </div>
          </div>

        </main>
      </div>

    </div>
  );
};

export default App;
